.home-container {
  width: 80%;
  margin: 0 auto;
}

.home-container h1 {
  margin: 1.5em auto;
  color: #3c3c3c;
}

.home-container p {
  line-height: 1.8em;
  font-size: 1.1em;
}
