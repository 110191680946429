.quote {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 8em auto 0 auto;
  height: 35vh;
  padding: 2em;
  border-radius: 10px;
  background-color: rgba(192, 192, 192, 0.159);
  box-shadow: rgba(11, 11, 11, 0.093) 4.4px 4.4px 5.2px;
}

.quote-text {
  font-size: 1.5em;
}

.loading-text {
  font-size: 1.8em;
}

.error-text {
  color: #ff2a2ae4;
  font-size: 1.6em;
}
